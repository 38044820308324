export default function langSwitchInit() {
  const nav = document.querySelector('.js-navigation');

  function getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  }

  let navPosition = getOffset(nav).left + nav.offsetWidth - 100;
  let langSwitchPosition = navPosition;
  document.documentElement.style.setProperty('--lang-switch-position', `${langSwitchPosition}px`);

  const langSwitch = document.querySelector('.js-lang-switch');
  langSwitch.style.opacity = '1';

  window.addEventListener('resize', () => {
    navPosition = getOffset(nav).left + nav.offsetWidth - 100;
    langSwitchPosition = navPosition;
    document.documentElement.style.setProperty('--lang-switch-position', `${langSwitchPosition}px`);
    langSwitch.style.opacity = '1';
  });
}
