import colorListInit from '../../../utils/colors-list';
import Youtube from '../../components/youtube/youtube';
import sliderInit from '../../components/slider/slider';
import CookieConsent from '../../components/cookie-consent/cookie-consent';
import Navigation from '../../components/navigation/navigation';
import langSwitchInit from '../../components/lang-switch/lang-switch';

// Cookie consent
async function cookies() {
  const cookieOptions = await import(`../../components/cookie-consent/cookie-options-${document.documentElement.lang}.json`);
  // eslint-disable-next-line no-unused-vars
  const consent = new CookieConsent(cookieOptions);
}

colorListInit();
sliderInit();
cookies();
langSwitchInit();

// eslint-disable-next-line no-unused-vars
const youtube = new Youtube();

// eslint-disable-next-line no-unused-vars
const navigation = new Navigation();
