/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import Swiper, { Pagination, Autoplay, Keyboard, Navigation } from 'swiper';
import 'swiper/css/bundle';

Swiper.use([Pagination, Autoplay, Keyboard, Navigation]);

export default function sliderInit() {
  const sliders = document.querySelectorAll('.js-swiper');

  sliders.forEach((slider) => {
    let options = {
      slidesPerView: 1,
      spaceBetween: 16,
      allowTouchMove: false,
      breakpoints: {
        460: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 100,
        },
        1023: {
          slidesPerView: 5,
          spaceBetween: 100,
        },
      },
    };

    const autoplay = slider.getAttribute('data-autoplay');
    if (autoplay) {
      const autoplayDelay = slider.getAttribute('data-autoplay-delay');
      options = { ...options, autoplay: { delay: autoplayDelay, disableOnInteraction: false }, loop: true };
    }

    const singleSlider = new Swiper(slider, options);
  });

  const slidersFull = document.querySelectorAll('.js-swiper-full');

  slidersFull.forEach((slider) => {
    let options = {
      updateOnWindowResize: true,
      watchOverflow: true,
      slidesPerView: 1.5,
      centeredSlides: true,
      spaceBetween: 32,
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      keyboard: {
        enabled: true,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 2.1,
        },
        1023: {
          slidesPerView: 3.5,
        },
        2000: {
          slidesPerView: 5.5,
        },
      },
    };

    const autoplay = slider.getAttribute('data-autoplay');
    if (autoplay) {
      const autoplayDelay = slider.getAttribute('data-autoplay-delay');
      options = { ...options, autoplay: { delay: autoplayDelay, disableOnInteraction: false }, loop: true };
    }

    const singleSlider = new Swiper(slider, options);
  });

  const slidersGallery = document.querySelectorAll('.js-swiper-gallery');

  slidersGallery.forEach((slider) => {
    let options = {
      slidesPerView: 1.1,
      spaceBetween: 32,
      allowTouchMove: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        460: {
          slidesPerView: 1.4,
        },
        768: {
          slidesPerView: 2,
        },
        1023: {
          slidesPerView: 3,
          spaceBetween: 48,
        },
      },
    };

    const autoplay = slider.getAttribute('data-autoplay');
    if (autoplay) {
      const autoplayDelay = slider.getAttribute('data-autoplay-delay');
      options = { ...options, autoplay: { delay: autoplayDelay, disableOnInteraction: false }, loop: true };
    }

    const singleSlider = new Swiper(slider, options);
  });
}
